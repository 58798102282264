import React from 'react';
import './ScorePanel.css';
// import { useStateValue } from "../StateProvider";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function ScorePanel(props) {
    // const [{ totalCorrect, totalAttempted }] = useStateValue();

    // console.log('inside ScorePanel');
    const percentage = getPercentage(props.score.totalCorrect, props.score.totalAttempted);
    return (
        <div className="scorepanel">
            {/* <div className="scorepanel__header">
                Score Panel
            </div> */}
            <div style={{
                width: 100, height: 100
            }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        strokeLinecap: 'round',
                        textSize: '20px',
                        pathTransitionDuration: 0.5,
                        pathColor: '#01bf71',
                        textColor: '#fff'
                    })}
                />
            </div>
            <div className="scorepanel__score">
                <span>{props.score.totalCorrect}</span>/<span>{props.score.totalAttempted}</span>
            </div>
        </div>
    )
}

function getPercentage(a, b) {
    if (b === 0) {
        return 0;
    } else {
        return Math.round(a * 100 / b);
    }
}

export default ScorePanel;
