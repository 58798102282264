import svg1 from '../../images/undraw_teaching_f1cm.svg';
import svg2 from '../../images/undraw_mathematics_4otb.svg';
import svg3 from '../../images/undraw_speech_to_text_9uir.svg';

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'What is Vedic Mathematics?',
    headLine: 'Solve mathematical numerical calculations in faster way',
    description: 'Vedic Mathematics is a collection of Techniques/Sutras to solve mathematical arithmetics in easy and faster way. It consists of 16 Sutras (Formulae) and 13 sub-sutras (Sub Formulae) which can be used for problems involved in arithmetic, algebra, geometry, calculus, conics.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: svg1,
    alt: 'About',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'learn',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Learn the sutras',
    headLine: 'Ekadhikena Purvena, Urdhva-Tiryagbyham',
    description: 'Get used to the basic fundamentals of vedic mathematics.',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: svg2,
    alt: 'Learn',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'practice',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Practice',
    headLine: 'Practice makes you perfect',
    description: 'Solve the sutras daily and make them the default choice to numerically calculate.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: svg3,
    alt: 'Practice',
    dark: true,
    primary: true,
    darkText: false
}