export const initialState = {
    user: null,
    totalCorrect: 0,
    totalAttempted: 0,
    history: []
};

const reducer = (state, action) => {
    // console.log(action);
    switch (action.type) {
        case 'CORRECT':
            return {
                ...state,
                totalCorrect: state.totalCorrect + 1,
                totalAttempted: state.totalAttempted + 1,
                history: [...state.history, action.question]
            };
        case 'NOT_CORRECT':
            return {
                ...state,
                totalAttempted: state.totalAttempted + 1,
                history: [...state.history, action.question]
            };
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
        default:
            return state;

    }
};

export default reducer;