import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import MathJax from 'mathjax3-react';
import { StateProvider } from './StateProvider';
import reducer, { initialState } from './reducer';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
    {/* <MathJax.Provider> */}
      <App />
    {/* </MathJax.Provider> */}
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

