import React, { useState } from 'react';
import { auth } from '../../firebase';
import { useHistory } from 'react-router-dom';
import logo from '../../images/VM_2.svg';
import './login.css';
import { useStateValue } from '../../StateProvider';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const [{}, dispatch] = useStateValue();

    const signIn = e => {
        e.preventDefault();
        //some fancy firebase login stuff
        auth.signInWithEmailAndPassword(email, password).then((auth) => {
            const user = auth.user;
            // console.log('Login - user: ', user);
            // console.log('user verified: ', user.emailVerified);
            if(user.emailVerified){
                // console.log('Login - user: ', user);
                dispatch({
                    type: 'SET_USER',
                    user: user
                });
                history.push("/home");
            }else{
                alert('User email not verified yet. Pls check your email and confirm your registration.');
            }
        }).catch(error => alert(error.message));
    }

    return (
        <div className="login__container">
            <div className="login__formwrap">
                <div className="login__formcontent">
                    <form className="login__form" action="#">
                        <div className="login__formheader">
                            <img src={logo} alt="logo" height="42px" />
                            <h1 className="login__formh1">Sign in to your account</h1>
                        </div>
                        <label className="login__formlabel" htmlFor="for">Email</label>
                        <input className="login__forminput" type="email" placeholder="Enter your email" 
                            required onChange={e => setEmail(e.target.value)} autoFocus/>
                        <label className="login__formlabel" htmlFor="for">Password</label>
                        <input className="login__forminput" type="password" placeholder="Enter your password" required onChange={e => setPassword(e.target.value)} />
                        <button className="login__formbutton" type="submit" onClick={signIn}>Continue</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;
