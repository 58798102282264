export const getURLPath = (type) => {
    switch (type) {
        case "sqrt2d":
            return process.env.REACT_APP_API_PATH_QUESTION_SQRT2D;
        case "cbrt2d":
            return process.env.REACT_APP_API_PATH_QUESTION_CBRT2D;
        case "sqr2d":
            return process.env.REACT_APP_API_PATH_QUESTION_SQR2D;
        case "cb2d":
            return process.env.REACT_APP_API_PATH_QUESTION_CB2D;
        case "sbtrct":
            return process.env.REACT_APP_API_PATH_QUESTION_SBTRCT;
        default:
            break;
    }
}