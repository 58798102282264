import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import '../Style.css';

function NavSubPanel() {

    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    const handleHistory = () => {

    };

    return (
        <div className="container__buttons">
            <Tooltip className="container__back" title="Back" enterDelay={500} leaveDelay={200}><Button type="submit" onClick={handleBack}><ArrowBackIcon color="primary" /></Button></Tooltip>
            <Tooltip className="container__history" title="History" enterDelay={500} leaveDelay={200}><Button type="submit" onClick={handleHistory}><HistoryIcon color="primary" /></Button></Tooltip>
        </div>
    )
}

export default NavSubPanel;
