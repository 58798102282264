export const getFormula = (question) => {
    // console.log('getformula: Type: ', question.type);
    switch (question.type) {
        case "sqrt2d":
        case "sqrt3d":
            return "\\sqrt{" + question.number + "}";
        case "cbrt2d":
        case "cbrt3d":
            return "\\sqrt[3]{" + question.number + "}";
        case "sqr2d":
        case "sqr3d":
            return question.number + "^2";
        case "cb2d":
        case "cb3d":
            return question.number + "^3";
        case "sbtrct":
            return "\\begin{array}{cc}&" + question.number + " \\\\ -&" + question.number2 + "\\end{array}";
        default:
            break;
    }
}

export const getHistoryFormula = (question) => {
    // console.log('history.question: ', question);
    switch (question.type) {
        case "sqrt2d":
        case "sqrt3d":
            return "\\sqrt{" + question.number + "} = {" + question.input + "}";
        case "cbrt2d":
        case "cbrt3d":
            return "\\sqrt[3]{" + question.number + "} = {" + question.input + "}";
        case "sqr2d":
        case "sqr3d":
            return question.number + "^2 = {" + question.input + "}";
        case "cb2d":
        case "cb3d":
            return question.number + "^3 = {" + question.input + "}";
        case "sbtrct":
            return "\\begin{array}{cc}&" + question.number + " \\\\ -&" + question.number2 + "\\end{array}";
        default:
            break;
    }
}
