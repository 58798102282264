import React from 'react';
import { useState } from 'react';
import History from '../components/History';
import QuestionPanel from '../components/QuestionPanel';
import ScorePanel from '../components/ScorePanel';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import '../App.css';
import { useLocation } from 'react-router-dom';
import NavSubPanel from '../components/NavSubPanel';

function Question(props) {

    // console.log('questionType: ', props.questionType);
    const location = useLocation();
    // console.log('location: ', location);
    // console.log('questionType: ', location.state.questionType);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const [score, setScore] = useState({
        totalCorrect: 0,
        totalAttempted: 0
    })
    const [history, setHistory] = useState([]);

    const addToHistory = (question, input) => {
        setHistory([...history, { ...question, input}]);
    }

    const handleScoreUpdate = (score) => {
        // console.log('inside App.handleScoreUpdate', score);
        setScore(score);
    }

    return (
        <div>
            {/* <Header /> */}
            {/* <Square/> */}
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} firm={true} />
            <NavSubPanel/>
            <div className="App-panel">
                {/* <SquareRoot onChange={() => handleChange()}/> */}
                {/* <CubeRoot/> */}
                {/* <Subtraction/> */}
                <QuestionPanel
                    setScore={(score) => handleScoreUpdate(score)}
                    addToHistory={(question, input) => addToHistory(question, input)}
                    score={score} 
                    questionType={location.state.questionType} />
                <History history={history} />
            </div>
            <ScorePanel score={score} />
        </div>
    )
}

export default Question;
