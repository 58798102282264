import React from 'react';
import { SidebarContainer, CrossIcon, Icon, SidebarWrapper, SidebarMenu, 
    SidebarLink, SideBtnWrap, SidebarRoute, SidebarLinkRoute } from './SidebarElements';

function Sidebar({ isOpen, toggle }) {

    const disableSignup = true;

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CrossIcon></CrossIcon>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="learn" onClick={toggle}>Learn</SidebarLink>
                    <SidebarLink to="practice" onClick={toggle}>Practice</SidebarLink>
                    <SidebarLinkRoute to={!disableSignup && "/signup"} onClick={toggle} disableSignup={disableSignup}>Sign Up</SidebarLinkRoute>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to="/login">Sign In</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;
