import React, { useEffect, useState } from 'react';
// import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, Img } from './NavbarElements';
import MenuIcon from '@material-ui/icons/Menu';
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../images/VM_3.svg';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import './navbar.css';
import { useStateValue } from '../../StateProvider';
import { auth } from '../../firebase';

function Navbar({ toggle, firm }) {

    const [scrollNav, setScrollNav] = useState(false);

    const [{ user }, dispatch] = useStateValue();

    const disableSignup = true;

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, [])

    useEffect(() => {
        // console.log('Navbar - user: ', user);
    }, [user]);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const handleAuthentication = () => {
        if(user) {
            // console.log('handleAuthentication.signOut');
            dispatch({
                type: 'SET_USER',
                user: undefined
            });
            auth.signOut();
        }
    };

    return (
            <nav className={firm ? "nav nav__scroll nav__firm" : (scrollNav ? "nav nav__scroll" : "nav")}>
                <div className="nav__container">
                    <LinkR className="nav__logo" to="/" onClick={toggleHome}>
                        <img className="nav__img" src={logo} alt="logo"></img>
                    </LinkR>
                    <div className="mobile__icon" onClick={toggle}>
                        <MenuIcon />
                    </div>
                    <ul className="nav__menu">
                        <li className="nav__item">
                            <LinkS className="nav__links" activeClass="nav__linksActive" to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>About</LinkS>
                        </li>
                        <li className="nav__item">
                            <LinkS className="nav__links" activeClass="nav__linksActive" to="learn" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Learn</LinkS>
                        </li>
                        <li className="nav__item">
                            <LinkS className="nav__links" activeClass="nav__linksActive" to="practice" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Practice</LinkS>
                        </li>
                        <li className="nav__item">
                            <LinkR className={disableSignup ? "nav__links nav__linksDisabled" : "nav__links"} 
                                to={disableSignup ? "" : "/signup"}>Sign Up</LinkR>
                        </li>
                    </ul>
                    <nav className="nav__btn">
                        <LinkR className="nav__btnLink" to={!user ? '/login' : '/'} onClick={handleAuthentication}>
                            {user ? 'Sign Out' : 'Sign In'}
                        </LinkR>
                    </nav>
                </div>
            </nav>
            // {/* <Nav scrollNav={scrollNav} firm={firm}>
            //     <NavbarContainer>
            //         <NavLogo to="/" onClick={toggleHome}>
            //             <Img src={logo} alt="logo" />
            //             {/* dolla */}
            //         </NavLogo>
            //         <MobileIcon onClick={toggle}>
            //             <MenuIcon />
            //         </MobileIcon>
            //         <NavMenu>
            //             <NavItem>
            //                 <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>About</NavLinks>
            //             </NavItem>
            //             <NavItem>
            //                 <NavLinks to="learn" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Learn</NavLinks>
            //             </NavItem>
            //             <NavItem>
            //                 <NavLinks to="practice" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Practice</NavLinks>
            //             </NavItem>
            //             <NavItem>
            //                 <NavLinks to="signup">Sign Up</NavLinks>
            //             </NavItem>
            //         </NavMenu>
            //         <NavBtn>
            //             <NavBtnLink to="/login">Sign In</NavBtnLink>
            //         </NavBtn>
            //     </NavbarContainer>
            // </Nav> */}
    )
}

export default Navbar;
