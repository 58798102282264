import React, { useState, useEffect } from 'react';
import AnswerPanel from './AnswerPanel';
// import MathJax from 'mathjax3-react';
import { getFormula } from '../formulaHelper';
import { getURLPath } from '../urlHelper';
import './Style.css';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function QuestionPanel(props) {

    // console.log('QuestionPanel.props: ', props);

    const [question, setQuestion] = useState({});
    // const [number, setNumber] = useState('');
    // const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const setScore = (score) => {
        //console.log('QuestionPanel.setScore', score);
        props.setScore(score);
    }

    const fetchQuestionAPI = () => {
        // console.log('inside fetchQuestionAPI() ...');
        // props.addToHistory(question);
        // console.log('NODE_ENV', process.env.NODE_ENV);
        // console.log('props: ', props);
        const type = props.questionType;
        // console.log('type: ', type);
        const url = getURLPath(type);
        // console.log('url: ', url);
        const fullURL = process.env.REACT_APP_QUESTION_SERVICE_BASE_URL + url;
        // console.log('Full URL: ', fullURL);
        fetch(fullURL)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json.number);
                // setNumber(json.number);
                // console.log(json.answer);
                // setAnswer(json.answer);
                setQuestion({
                    type: props.questionType,
                    number: json.number,
                    number2: json.number2,
                    answer: json.answer,
                    id: json.id
                });
            },
                err => {
                    setIsLoading(false);
                    console.log('error!!!');
                });
    };

    useEffect(() => {
        // console.log('Question Panel got rendered from useEffect!!');
    });

    useEffect(() => {
        fetchQuestionAPI();
        // console.log('invoked question for first time ...');
    }, []);

    return (
        <div className="container">
            {/* <div className="number"> */}
            {/* <MathJax.Provider>
                    <MathJax.Formula formula={"$$" + getFormula(question) + "$$"} />
                    <MathJax.Formula formula="$$\int x^2dx$$" />
                </MathJax.Provider> */}
            {/* <BlockMath math="\int_0^\infty x^2 dx"/>getFormula(question) */}
            {Object.keys(question).length !== 0 &&
                <div id="question" className="question">
                    {<BlockMath>{getFormula(question)}</BlockMath>}
                    <AnswerPanel
                        answer={question.answer}
                        fetchQuestion={() => fetchQuestionAPI()}
                        setScore={(score) => setScore(score)}
                        score={props.score}
                        addToHistory={(input) => props.addToHistory(question, input)}
                        type={props.type} />
                </div>}
            {Object.keys(question).length === 0 && isLoading &&
                <><p>Loading </p><Loader
                    type="ThreeDots"
                    color="#01bf71"
                    height={100}
                    width={100} //3 secs
                /></>}
            {/* </div> */}
        </div>
    )
}

export default QuestionPanel;
