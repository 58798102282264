import React from 'react';
import Login from '../components/Login';

function Signin() {
    return (
        <>
            <Login/>
        </>
    )
}

export default Signin;
