import React from 'react';
import { Button, TextField, FormGroup, Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { useState } from "react";
import './Style.css';
import { ArrowForward } from '@material-ui/icons';

function AnswerPanel(props) {

    const [input, setInput] = useState('');
    const [open, setOpen] = useState(false);
    const [lastIncorrectAnswer, setlastIncorrectAnswer] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const scoreCorrectAnswer = (correct) => {
        // console.log('inside scoreCorrectAnswer ', correct);
        let score = props.score;
        if (correct) {
            score.totalCorrect += 1;
        }
        score.totalAttempted += 1;
        props.setScore({
            totalCorrect: score.totalCorrect,
            totalAttempted: score.totalAttempted
        });
    }

    const submitAnswer = (event) => {
        event.preventDefault();
        if (Number(props.answer) === Number(input)) {
            // console.log('Correct!');
            //totalCorrect++
            scoreCorrectAnswer(true);
        } else {
            // console.log('Not Correct!');
            //setLastIncorrectAnswer(answer);
            //handleAnswerMismatch();
            setlastIncorrectAnswer(props.answer);
            scoreCorrectAnswer(false);
            setOpen(true);
        }
        setInput('');
        //fetchQuestionAPI();
        props.addToHistory(input);
        props.fetchQuestion();
    };

    // console.log('inside AnswerPanel');

    return (
        <div className="questionPanel">
            <form className="formGroup" onSubmit={submitAnswer}>
                <FormGroup noValidate autoComplete="off">
                    <TextField
                        className="textField"
                        id="standard-basic"
                        // variant="outlined"
                        color="primary"
                        inputProps={
                            {
                                style: {
                                    fontFamily: 'Times New Roman',
                                    color: 'aliceblue',
                                    fontSize: 80,
                                    textAlign: 'center',
                                    width: 300,
                                    borderBottom: 'yellow'
                                }
                            }}
                        value={input}
                        onChange={event => setInput(event.target.value)}
                        autoFocus={true}
                        placeholder=""
                    />
                    
                </FormGroup>
                <Button type="submit" disabled={!input} onClick={submitAnswer} variant="contained" color="primary"><ArrowForward/></Button>
            </form>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert severity="error" className="alert">
                    <AlertTitle>Error</AlertTitle>
                    The correct answer is <strong>{lastIncorrectAnswer}</strong>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AnswerPanel;
