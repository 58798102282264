import React from 'react';
// import MathJax from 'mathjax3-react';
import './HistoryQuestion.css';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getHistoryFormula } from '../formulaHelper';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { green, red } from '@material-ui/core/colors';

function HistoryQuestion(props) {
    var correct = false;
    if (Number(props.question.answer) === Number(props.question.input)) {
        correct = true;
        // console.log('correct: ', correct);
    }
    // const formula = "\\sqrt{" + props.question.number + "} = {" + props.question.input + "}";
    return (
        <div className="historyQuestion">
            {/* <MathJax.Provider> */}
                {/* <MathJax.Formula formula={"$$" + getHistoryFormula(props.question) + "$$"} /> */}
            {/* </MathJax.Provider> */}
            <BlockMath>{getHistoryFormula(props.question)}</BlockMath>
            {correct && <CheckIcon style={{ color: green[500] }}/>}
            {!correct && <ClearIcon style={{ color: red[500] }}/>}
        </div>
    )
}

export default HistoryQuestion;
