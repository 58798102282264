import React, { useEffect, useRef } from 'react';
// import Element from './Element';
import './History.css';
import HistoryQuestion from './HistoryQuestion';

import { useStateValue } from '../StateProvider';

function History(props) {
    const [{ history }] = useStateValue();
    // const history = props.history;
    // console.log('history: ', history);

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [history])

    return (
        <>
            <div className="history">
                <div className="history__header">
                    History
                </div>

                {history.map(question => (
                    <HistoryQuestion key={question.id} question={question} />
                    // <Element key={question.id} question={question}/>
                ))}

                <div ref={messagesEndRef} />

            </div>
        </>
    )
}

export default History;
