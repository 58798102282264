import React from 'react';
import Navbar from '../components/Navbar';
import QuestionCard from '../components/QuestionCard';
import Icon1 from '../images/undraw_mathematics_4otb.svg';

function UserHome() {
    return (
        <>
            <Navbar />
            <div className="home">
                {/* <h1 className="home__h1">Choose</h1> */}
                <div className="home__wrapper">
                    <QuestionCard icon={Icon1} heading="Square Root" text="2 digit Square Root"
                        formula={"\\sqrt{abcd}"} to="/question" questionType="sqrt2d" disabled={false}/>
                    <QuestionCard icon={Icon1} heading="Cube Root" text="2 digit Cube Root"
                        formula={"\\sqrt[3]{abcdef}"} to="/question" questionType="cbrt2d" disabled={false}/>
                    <QuestionCard icon={Icon1} heading="Square" text="Square of a 2 digit number"
                        formula={"ab^2"} to="/question" questionType="sqr2d" disabled={false}/>
                    <QuestionCard icon={Icon1} heading="Cube" text="Cube of a 2 digit number"
                        formula={"ab^3"} to="/question" questionType="cb2d" disabled={false}/>
                    <QuestionCard icon={Icon1} heading="Subtraction" text=""
                        formula={"\\begin{array}{cc}&abc \\\\ -&xyz\\end{array}"} to="/question" questionType="sbtrct" disabled={false}/>
                    {/* <QuestionCard icon={Icon1} heading="Subtraction" text=""
                        formula="\begin{array}{cc}a&=b+c\\d+e&=f\end{array}" to="/question" disabled={true}/> */}
                </div>
            </div>
        </>
    )
}

export default UserHome;
