import React from 'react';
import Signup from '../components/Login/Signup';

function Register() {
    return (
        <>
            <Signup/>
        </>
    )
}

export default Register;
