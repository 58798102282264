import React from 'react';
import './questioncard.css';
// import MathJax from 'mathjax3-react';
import { Link } from 'react-router-dom';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

function QuestionCard({ disabled, heading, text, formula, to, questionType }) {
    return (
        <Link className="home__link" to={{
            pathname: to,
            state: {questionType: questionType}
        }} >
            <div className="home__card" style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                <div className="number2">
                    {/* <MathJax.Provider>
                        <MathJax.Formula formula={"$$" + (formula) + "$$"} />
                    </MathJax.Provider> */}
                    <BlockMath>{formula}</BlockMath>
                </div>
                {/* <img className="home__img" src={icon} alt=""></img> */}
                <h2 className="home__h2">{heading}</h2>
                <p className="home__p">{text}</p>
            </div>
        </Link>
    )
}

export default QuestionCard;
