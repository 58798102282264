import React, { useState } from 'react';
import { auth } from '../../firebase';
import { useHistory } from 'react-router-dom';
import logo from '../../images/VM_2.svg';
import './login.css';

function Signup() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const register = e => {
        e.preventDefault();
        auth.createUserWithEmailAndPassword(email, password)
        .then((auth) => {
            console.log(auth);
            auth.user.sendEmailVerification();
            alert("Check email, verify email address and then login");
            history.push("/login");
        }).catch(error => alert(error.message));
    }

    return (
        <div className="login__container">
            <div className="login__formwrap">
                <div className="login__formcontent">
                    <form className="login__form" action="#">
                        <div className="login__formheader">
                            <img src={logo} alt="logo" height="42px" />
                            <h1 className="login__formh1">Sign up with your email</h1>
                        </div>
                        <label className="login__formlabel" htmlFor="for">Email</label>
                        <input className="login__forminput" type="email" placeholder="Provide an email"
                            required onChange={e => setEmail(e.target.value)} autoFocus/>
                        <label className="login__formlabel" htmlFor="for">Password</label>
                        <input className="login__forminput" type="password" placeholder="Choose a password" required onChange={e => setPassword(e.target.value)} />
                        <button className="login__formbutton" type="submit" onClick={register}>Sign Up</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Signup;
