import './App.css';
import './components/History.css';
// import Header from './components/Header';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import Signin from './pages/login';
import UserHome from './pages/userhome';
import Question from './pages/question';
import Register from './pages/signup';
// import Element from './components/Element';
// import Square from './components/Square';
// import SquareRoot from './components/SquareRoot';
// import CubeRoot from './components/CubeRoot';
// import Subtraction from './components/Subtraction';

function App() {

  // const [totalAttempted, setTotalAttempted] = useState(0);
  // const [totalCorrect, setTotalCorrect] = useState(0);
  

  // const handleChange = () => {
  //   console.log("inside App.handleChange");
  // };

  

  return (
    <Router>
      <Switch>
      <Route exact path="/">
          <Home />
        </Route>
        <Route path="/signup">
          <Register/>
        </Route>
        <Route path="/login">
          <Signin />
        </Route>
        <Route path="/home">
          <UserHome />
        </Route>
        <Route path="/question">
          <Question/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

